import React from 'react'
import { Link } from 'gatsby'

import {
  Button,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

// fourOhFour's the new 3OH3!
import fourOhFourImage from '../images/404/f0ccce9d-711f-42e2-a861-9265c67f7811_rw_1200.gif'


const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <PageTitle>Page Not Found</PageTitle>
        <TitleBorder />
        <div className="justify-center md:justify-end">
          <div>
            <h2>Oh deer, we had an accident.</h2>
            <p className="lead">The page you were looking for was moved or does not exist.</p>
            <p className="lead">Let's get you back on track:</p>
            <Link to="/" title="Science Education Leadership Development Programs & Curriculum">
              <Button
                variant="blue"
                style={{
                  width: '100%',
                  fontSize: 'calc(1.305rem + .66vw)',
                }}
                title="Home"
              >
                Home
              </Button>
            </Link>
          </div>
          <div className="w-4/5 md:w-1/2 lg:w-2/5">
            <img
              src={fourOhFourImage}
              className="rounded mb-4 md:mb-0"
              alt="Injured deer on crutches hobbling away from the road."
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

